import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { Button } from 'base-ui'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokButtonV2Type } from '../../model/storyblokTypes.generated'

export const StoryblokButtonV2: FC<
  StoryblokComponentProps<StoryblokButtonV2Type>
> = ({ blok }) => {
  const { href, text, ...restProps } = blok

  if (!href) {
    return (
      <Button {...storyblokEditable(blok)} type="button" {...restProps}>
        {text}
      </Button>
    )
  }

  return (
    <Button<'a'>
      {...storyblokEditable(blok)}
      href={href.url}
      rel={href.target === '_blank' ? 'noreferrer' : undefined}
      slots={{ root: 'a' }}
      target={href.target}
      {...restProps}
    >
      {text}
    </Button>
  )
}
